import React, {useState} from 'react'
import EmailTelephone from './email-telephone'
import classnames from 'classnames'
import {localiseMessage} from '../helpers/locale'
import {PING_US_MESSAGE, NAME, PHONE, SUBJECT, MESSAGE, SENT, SEND} from '../helpers/messages'

export default function Contact ({show, onCloseClick}) {

  const [subscribeFormState, setSubscribeFormState] = useState('unsubmitted')
  const [contactFormState, setContactFormState] = useState('unsubmitted')
  const artificialDelay = 3000

  const submitForm = formEl => {

    return fetch(formEl.action, {

      method:'post',
      body: new FormData(formEl)

    }).then(() => new Promise(res => setTimeout(res, artificialDelay * .5)))
  }

  const formSubmitted = e => {

    e.preventDefault()

    const formEl = e.target
    const setFormState = formEl.getAttribute('name') == 'contact' ? setContactFormState : setSubscribeFormState

    setFormState('submitting')

    submitForm(formEl).then(() => {

      formEl.reset()

      setFormState('submitted')

      setTimeout(() => setFormState('unsubmitted'), artificialDelay)

    })
  }

  const contactFormSubmitClicked = () => setContactFormState('validating')

  const className = classnames({
    'contact-modal': true,
    'contact-modal--show': show
  })

  const subscribeFormClasses = classnames({
    'contact-modal__subscribe': true,
    [`form--${subscribeFormState}`]: true
  })

  const contactFormClasses = classnames({
    'contact-modal__message': true,
    [`form--${contactFormState}`]: true
  })

  return (

    <section className={className}>
      <div className="contact-modal__lightbox" onClick={onCloseClick}></div>
      <div className="contact-modal__box">
        <div className="contact-modal__close" onClick={onCloseClick}></div>
        <div className="contact-modal__inner">
          <div className="contact-modal__item">
            <EmailTelephone />
          </div>
          <div className="contact-modal__item">
            <h1>{localiseMessage(PING_US_MESSAGE)}</h1>
            <form name="contact" className={contactFormClasses} data-netlify="true" onSubmit={formSubmitted} onInput={() => setContactFormState('unsubmitted')} >
              <input name="name" placeholder={localiseMessage(NAME)} required />
              <input name="email" type="email" placeholder="Email" required />
              <input name="phone" type="tel" placeholder={localiseMessage(PHONE)} />
              <select name="subject" defaultValue="" required>
                <option value="">{localiseMessage(SUBJECT)}</option>
                <option value="Experiential">Experiential</option>
                <option value="Retail">Retail</option>
                <option value="Network">Network</option>
                <option value="Support">Support</option>
                <option value="Investment">Investment</option>
                <option value="Other">Other</option>
              </select>
              <textarea name="message" placeholder={localiseMessage(MESSAGE)} required></textarea>
              <input type="hidden" name="form-name" value="contact" />
              <input className="button" type="submit" value={contactFormState == 'submitted' ? localiseMessage(SENT) : localiseMessage(SEND)} onClick={contactFormSubmitClicked} />
            </form>
          </div>
          <div className="contact-modal__item">
            {/* <EmailTelephone /> */}
          </div>
          <div className="contact-modal__item">
            {/* <EmailTelephone /> */}
          </div>
        </div>
      </div>
    </section>
  )
}

import React from 'react'
import {LocaleLink} from '../helpers/locale'
import EmailTelephone from './email-telephone'
import SubscribeStrap from './subscribe-strap'
import {StaticQuery, graphql} from 'gatsby'
import {SOCIAL_URLS, LOCALES} from '../helpers/constants'
import {useLocale, localiseObject, getLanguageFromLocale, setLocale} from '../helpers/locale'

export default function Footer () {

  const locale = useLocale()

  const onLocaleChange = e => setLocale({fromLocale: locale, toLocale: e.target.value})

  return (
    <>
      <SubscribeStrap />
      <footer className="footer">
        <div className="footer__inner">
          <div className="footer__item">
            <EmailTelephone />
          </div>
          <div className="footer__item">
            <img className="footer__logo" src="/images/logo--white.svg" />
          </div>
          <div className="footer__item">
          </div>
          <div className="footer__item">
            <div className="footer__locale">
              <select className={`select-locale select-locale--${locale}`} name="locale" defaultValue={locale} onChange={onLocaleChange}>
                {LOCALES.map((locale, i) => (
                  <option key={i} value={locale}>{getLanguageFromLocale(locale)}</option>
                ))}
              </select>
            </div>
            <div className="footer__legal">
              <StaticQuery
                query={graphql`
                  query AncillaryPagesQuery {
                    allSanityAncillaryPage {
                      nodes {
                        id
                        slug {
                          current
                        }
                        title {
                          en
                          fr
                        }
                      }
                    }
                  }
                `}
                render={({allSanityAncillaryPage: {nodes}}) => {
                  return nodes.map(({title, slug}, i) => (
                    <LocaleLink key={i} to={slug && `/${slug.current}`}>{localiseObject(title)}</LocaleLink>
                  ))
                }}
              />
              <a href="https://developer.vendos.io/" target="_blank" rel="noopener noreferrer">VendOS Documentation</a>
              <br/>
              <sub>
                All content © Social Vend Ltd. 2024<br/>
                Social Vend® and Vendmax® are registered trademarks of Social Vend Ltd. in the United Kingdom and/or other countries. All other trademarks cited herein are the property of their respective owners.
              </sub>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
